import React, { useState } from 'react';

import Myheaderlist from './Ergo/Myheaderlist.js'
import Home from './Ergo/Home'
import Item from './Ergo/Item'
import Category from './Ergo/Category'

import { NavLink, BrowserRouter as Router, Routes, Route, Redirect } from 'react-router-dom';

function IndexPage() {
  return (
    <div className='wrapper antialiased'>
      <div className='max-w-8xl mx-auto mb-20 md:mb-32 px-4 sm:px-6 lg:px-8'>
        <Home />
      </div>
    </div>
  );
};
   
function ItemPage() {
  return (
    <div className='wrapper antialiased'>
      <div className='max-w-8xl mx-auto mb-20 md:mb-32 px-4 sm:px-6 lg:px-8'>
        <Item />
      </div>
    </div>
  );
}

function ItemHead() {
  return (
    <div className='wrapper antialiased'>
      <div className='max-w-8xl mx-auto mb-20 md:mb-32 px-4 sm:px-6 lg:px-8'>
        <Myheaderlist />
      </div>
    </div>
  );
}

function CategoryPage() {
  return (
    <div className='wrapper antialiased'>
      <div className='max-w-8xl mx-auto mb-20 md:mb-32 px-4 sm:px-6 lg:px-8'>
        <Category />
      </div>
    </div>
  );
}

function App() {
  const [navigate, setNavigate] = useState(false) 

  return (
    <div>
    <Router>

      <Myheaderlist />

      <div className="container mx-auto mt-16">
        <Routes>
          <Route exact path="/" Component={IndexPage} />
          <Route path="/item/:categoryId" Component={ItemPage} />
          <Route path="/category/:categoryId" Component={CategoryPage} />
        </Routes>
      </div>
    </Router>
    </div>
  );
};

export default App;

export const Head = () => (
  <>
    <title>Hello World</title>
    <meta name="description" content="Hello World" />
  </>
)