
import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

function App() {

  const [item, setItem] = useState(0)
  const TITLE = 'My Page Title';


  const params = useParams();
  const URL = process.env.REACT_APP_API + "item/" + params.categoryId;
  const [activeImg, setActiveImage] = useState(item.imgsrc)

  useEffect(() => {
    const fetchData = async () => {
        const result = await fetch(URL)
        result.json().then(json => {
            setItem(json)
            setActiveImage(json.imgsrc)
            console.log(json)
        })
    }
    fetchData();
  }, [])

  return (
    <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-center'>
        <Helmet>
          <title>{ TITLE }</title>
          <meta property="og:image" content={process.env.REACT_APP_DOMAIN + item.imgsrc} />
        </Helmet>
            <div className='flex flex-col gap-6 lg:w-2/4'>
                <img src={process.env.REACT_APP_DOMAIN + activeImg} alt="" className='w-full object-cover rounded-xl'/>
                <div className='relative rounded-xl overflow-auto'>
                <div className='flex flex-row space-x-4 justify-between h-24 max-[600px]:h-10 overflow-hidden'>
                    
                    {(() => {
                        if (item.imgsrc2) {
                            return (
                                <img src={process.env.REACT_APP_DOMAIN + item.imgsrc} alt="" className='basis-1/4 rounded-lg flex items-center justify-center bg-fuchsia-500 shadow-lg rounded-md cursor-pointer' onClick={() => setActiveImage(item.imgsrc)}/>
                            )
                        }
                        return null;
                    })()}

                    {(() => {
                        if (item.imgsrc2) { 
                            return (
                                <img src={process.env.REACT_APP_DOMAIN + item.imgsrc2} alt="" className='basis-1/4 rounded-lg flex items-center justify-center bg-fuchsia-500 shadow-lg rounded-md cursor-pointer' onClick={() => setActiveImage(item.imgsrc2)}/>
                            )
                        }
                        return null;
                    })()}

                    {(() => {
                        if (item.imgsrc3) {
                            return (
                                <img src={process.env.REACT_APP_DOMAIN + item.imgsrc3} alt="" className='basis-1/4 rounded-lg flex items-center justify-center bg-fuchsia-500 shadow-lg rounded-md cursor-pointer' onClick={() => setActiveImage(item.imgsrc3)}/>
                            )
                        }
                        return null;
                    })()}
                        
                    {(() => {
                        if (item.imgsrc4) {
                            console.log("fuck")
                        }
                        if (item.imgsrc4) {
                            return (
                                <img src={process.env.REACT_APP_DOMAIN + item.imgsrc4} alt="" className='basis-1/4 rounded-lg flex items-center justify-center bg-fuchsia-500 shadow-lg rounded-md cursor-pointer' onClick={() => setActiveImage(item.imgsrc4)}/>
                            )
                        }
                        return null;
                    })()}
                    
                    </div>
                </div>
            </div>
            {/* ABOUT */}
            <div className='flex flex-col gap-4 lg:w-2/4'>
                <div>
                    <h1 className='text-3xl font-bold'>{item.name}</h1>
                </div>
                <h6 className='text-2xl font-semibold'>{item.price}</h6>
                <p className='text-gray-700 whitespace-pre-wrap'>
                {item.description}
                </p>
                
            </div>
        </div>
  );
}

export default App;

