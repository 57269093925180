import React, {useState, useEffect} from 'react';
import { NavLink, BrowserRouter as Router, Routes, Route, Redirect } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';


function App() {

    const [products, setProducts] = useState([])
    const params = useParams();
    const URL = process.env.REACT_APP_API + "category/" + params.categoryId;

    const location = useLocation();

    const [error, setError] = React.useState("");

    useEffect(() => {
      const fetchData = async () => {
          const result = await fetch(URL)
          result.json().then(json => {
            if (json) {
                setProducts(json)
            } else {
                setProducts([])
            }
          })
      }
      fetchData();
    }, [location])

    if (products.length == 0) {
        return (
            <div>
                empty
            </div>
        )
    } else {
        return (
            <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-4 lg:max-w-7xl lg:px-8">
              <h2 className="sr-only">Products</h2>
      
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {products.map((product) => (
                  <a key={product.id} href={"/item/" + product.id} className="group">
                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                      <img
                        src={process.env.REACT_APP_DOMAIN + product.imgsrc}
                        alt={product.imgalt}
                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                      />
                    </div>
                    <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
                    <p className="mt-1 text-lg font-medium text-gray-900">{product.price}</p>
                  </a>
                ))}
              </div>
            </div>
          </div>
        );
    }
}

export default App;
