import React, { useState, useEffect } from 'react';

function App() {

        return (
            <div className="text-gray-700 whitespace-pre-wrap">
                <h3 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl">Правила</h3>
                <p>Для того чтобы купить что-то отсюда можете написать в телегу в лс @devpew</p>
                <p>Чтобы всем было все очевидно сразу же рассказываю некоторые правила.</p>
                <p>0. Нет торга. Нет обмена. Есть только продажа по указанной цене.</p>
                <p>0.01. Криптой можно. Только ETH. Никакие другие коины и токены не интересны.</p>
                <p>0.1. Все продается именно в том виде в котором выложено. Можно ли купить без кейкапов? Нельзя. Можно ли купить без свитчей? Нельзя. Можно ли поменять что-то на что-то? Нельзя. </p>
                <p>1. Не нужно спрашивать: "Эта клавиатура ещё продаётся?". Если выложена тут значит продаётся. </p>
                <p>2. Не нужно спрашивать: "Я видел у вас клавиатуру. Как ее купить?". Если клавиатура тут не выложена, значит никак.</p>
                <p>3. Купить можно все лично, живу в СПб. Но могу и отправить через СДЭК. </p>
                <p>4. Нет броней. Ничего нельзя отложить. Ничего нельзя придержать. </p>
                <p>5. Если вы видели обьявление с какой-то клавиатурой, а теперь его нет, то его нет. Это значит что клавиатура была продана. Если вы видели у меня какую-то клавиатуру, а тут ее нет, значит она не продается.</p>
                <p>6. Полная предоплата при доставке. Доставку оплачиваете вы при получении в пункте выдачи. Для доставки нужны ваши ФИО, адрес пункта выдачи и номер телефона.</p>
                <p>7. Отправить в другую страну, наверное, можно. Узнать про условия доставки и про все остальное нужно вам самим. Я не знаю сколько это стоит. Я не знаю кто доставляет, а кто не доставляет. Для отправки в другую страну вы САМОСТОЯТЕЛЬНО все организовываете.</p>
                <p>8. Не собираю клавиатуры под заказ. Я продаю то, что мне самому интересно было собрать для себя. </p>
                <p>8.1 Не люблю давать обещания, которые могу не давать. Поэтому я не знаю а будет ли у меня такая клавиатура только в красном цвете. Я не знаю будет ли у меня какая-то другая клавиатура и когда это будет. На все вопросы о будущем ответ только один - НЕ ЗНАЮ.</p>
                <p>9. Если я продаю наборы для сборки, то вам самостоятельно нужно будет все гуглить. Я не оказываю консультационных услуг, они не входят в стоимость набора. Конечно, советом помочь могу, но могу и не помочь. По крайней мере такой обязанности у меня нет.</p>
                <p>9.1. Клавиатуры идут со стоковыми прошивками. Если вам нужно поменять что-то в прошивке, то вы это делаете сами, к клавиатуре не идут в комплекте мои консультации по прошивкам.</p>
                <p>10. Ни к какой клавиатуре не идут кабели. Для подключения нужен кабель type-c, который умете передавать не только зарядку, но и дату. Если клавиатура не работает, то убедитесь, что у вас нужный кабель. Кабели TRRS идут в комплекте (это те кабели которые между двумя половинами, если клавиатура проводная) </p>
            
                <h3 className="mt-4 mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl">Про возвраты</h3>
                <p>У меня нет возврата. Если вы покупаете клавиатуру у меня, то нет опции возврата денег.</p>
                <p>При этом если вдруг что-то не работает, то я абсолютно точно бесплатно ее починю. При этом отправку, которую вы сделаете будет за мой счет. И после ремонта сделаю доставку тоже за свой счет. </p>
                <p>НО. Если вдруг окажется, что клавиатура не работает просто из-за того, что отошла плата, вынулся свитч, вы что-то не туда вставили, залили кривую прошивку или что-то еще, то вы заплатите за доставку от вас до меня и потом от меня обратно к вам </p>
                <p>Само собой я не чиню бесплатно все механические поломки. Если вы стукнули кулаком по клавиатуре, то ремонтировать придется самому. Так же гарантия само собой не вечная. И если у вас сломалось что-то через пол года, то вам чинить нужно тоже самим (хотя у меня был один случай, когда я чинил такое бесплатно даже через очень большой отрезок времени)</p>
                <p>Так же, под гарантию не попадают поломки самого контроллера. Его вы можете сжечь или окирпичить. </p>
                <p>Клавиатуры, которые продаются тут это клавиатуры для энтузиастов. Нужно иметь хотя бы немного интереса к ним и понимания того как они работают хотя бы на базовом уровне.</p>
            </div>
        )
}

export default App